import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Curs Presencial Preparació Adequació Psicoprofessional Mossos d'Esquadra 2024
                    [Grup B16 - 3 març de 15.00 a 20.00 h]
                </Title>
                <Text>
                    <br />
                    <br />
                    Us presentem el millor curs presencial per preparar la 3a prova d'accés a
                    l'oposició lliure de les 850 places de Mossos d'Esquadra escala bàsica.
                    <br />
                    <br />
                    Enguany amb NOVETATS i encara molt més personalitzat.
                    <br />
                    <br />
                    5 hores de curs presencial, amb un dossier de franc, correcció personalitzada
                    del CV i BIODATA e informe per part d'una psicopedagoga especialista en emocions
                    i gesticulació corporal.
                    <br />
                    <br />
                    Metodologia iOpos:
                    <br />
                    <br />
                    Per iniciar el curs cada alumne tindrà 2 minuts per presentar-se davant dels
                    companys i una psicòloga especialista en emocions realitzarà un informe
                    personalitzat que s'entregarà conjuntament el dia de la preparació d'entrevista.
                    <br />
                    <br />
                    Ens centrarem en explicacions detallades del test de personalitat, competencials
                    i clínics, amb la novetat del test de l'any anterior on us explicarem com
                    afrontar-lo amb garanties.
                    <br />
                    <br />
                    Explicació detallada del confeccionament del BIODATA i CV que serà la vostra
                    carta de presentació de l'entrevista i un punt molt important.
                    <br />
                    <br />
                    Cada alumne disposarà d'un dossier amb tots els continguts necessaris
                    <br />
                    <br />
                    Un cop finalitzat el curs cada alumne confeccionarà el seu BIODATA de forma
                    individual i l'haurà d'enviar a través del mail biodataiopos@iopos.academy.
                    <br />
                    <br />
                    Cada alumne tindrà una correcció personalitzada a càrrec d'un docent
                    especialitzat i ex-membre de tribunal qualificador.
                    <br />
                    <br />
                    El curs es realitza al carrer Martí Codolar núm 18, Hospitalet de Llobregat.
                    <br />
                    <br />
                    Com veus aquest any la formació és encara més personalitzada i més complerta.
                    <br />
                    <br />
                    Si vols seguretat i resultats, iOpos Acadèmia és la teva millor elecció.
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/VcAo3IAsCmk"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
